import { Box, Button, Divider, Drawer, IconButton, Typography } from "@mui/material"
import { IconX } from "@tabler/icons-react"
import { useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { CheckboxMultiple } from "~/components"
import { SearchQueysData, useQueryString } from "~/hooks"
import { userStatusOptions } from "~/utils/options"

type FilterData = {
  name?: string,
  status?: string[],
}

type FilterUsersListDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

export const FilterUsersDrawer: React.FC<FilterUsersListDrawerProps> = ({ drawerIsOpen, queryKeys, setDrawerIsOpen, toggleDrawer }) => {
    const formMethods = useForm()
  
    const { searchQuerys, setSearchParams } = useQueryString()
    const queryValues = searchQuerys(queryKeys)
  
    useEffect(() => {
      if(drawerIsOpen) {
        formMethods.setValue('status', queryValues.status)
        formMethods.setValue('name', queryValues.name)
      }
    }, [drawerIsOpen])
  
    const handleFilters = (filters: FilterData) => {
      setDrawerIsOpen(false)
      setSearchParams(state => {
        state.set('page', '0')
        if(filters?.status && filters?.status?.length > 0 || queryValues?.status?.length > 0) {
          state.set('status', JSON.stringify(filters.status) || queryValues.status)
        } else {
          state.delete('status')
        }
        filters.name  || queryValues.name  ? state.set('name', filters.name || queryValues.name) : state.delete('name')
        return state
      })
    }
  
  
    const onRemoveFilters = () => {
      setDrawerIsOpen(false)
      setSearchParams(state => {
        state.set('page', '0')
        state.delete('name')
        state.delete('status')
        return state
      })
    }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX  />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters({ ...formData }))}>
          <Typography sx={{ paddingTop: '1rem' }} fontWeight={500}>Status</Typography>
          <CheckboxMultiple name='status' options={userStatusOptions} />
          <Divider sx={{ paddingTop: '1.4rem' }} />
          <Box sx={{ display: 'flex', padding: '2rem 0', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={() => onRemoveFilters()} type='button' fullWidth color='secondary'>Limpar</Button>
            <Button type='submit' fullWidth color='primary'>Aplicar</Button>
          </Box>
        </form>
      </FormProvider>
    </Drawer>
  )
}