import { useTheme } from '@emotion/react'
import { Box, Button, IconButton } from '@mui/material'
import { IconX } from '@tabler/icons-react'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchQueysData } from '~/hooks'

type FilterButtonProps = {
  filterCounter: number
  toggleDrawer: () => void
  filtersToClear: SearchQueysData[]
  onCustomClear?: Function
}

export const FilterButton: React.FC<FilterButtonProps> = ({ filterCounter, toggleDrawer, filtersToClear, onCustomClear })  => {
  const [, setSearchParams] = useSearchParams()
  const theme = useTheme()

  const onRemoveFilters = useCallback(() => {
    onCustomClear && onCustomClear()
    setSearchParams(state => {
      state.set('page', '0')
      filtersToClear.map(({ key, defaultValue }) => {
        if(defaultValue && defaultValue.length > 0) {
          state.set(key, JSON.stringify(defaultValue))
        } else {
          state.delete(key)
        }
      })
      return state
    })
  }, [])

  return (
    <>
      {filterCounter > 0 ? (
        <Box sx={{ position: 'relative' }}>
          <Button
            onClick={toggleDrawer}
            sx={{ display: 'flex', alignItems: 'center', gap: '.6rem', paddingRight: '3.6rem' }}
            color='primary'
            variant='outlined'
          >
            <Box sx={{
              background: theme.palette.common.white,
              padding: '.4rem',
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              lineHeight: '10px'
            }}>
              {filterCounter}
            </Box>
              Filtros
          </Button>
          <IconButton sx={{ padding: '4px', position: 'absolute', top: '8px', right: '12px' }} onClick={() => onRemoveFilters()}><IconX /></IconButton>
        </Box>
      ) : 

        <Button onClick={toggleDrawer} color='secondary'>Filtros</Button>
      }
      
    </>
  )
}
