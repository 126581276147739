import { EmailOutlined } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography, useMediaQuery } from '@mui/material'
import { useCallback } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Input } from '~/components/Form/Input'
import { useAffiliatePartnerUserRecoverPasswordMutation, useAffiliatePartnerUserChangePasswordMutation } from '~/graphql/types'
import { LinkWithNoStyle } from '~/styles'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTheme } from '@emotion/react'
import {  IconCircleFilled, IconLock } from '@tabler/icons-react'
import * as yup from 'yup'
import { passwordRegex } from '~/utils'
import { RequirementsRecover } from './styles'

type RecoverPasswordFormData = {
  email: string
  newPassword: string,
  retypeNewPassword: string
}

type RecoverPasswordFormProps = {
  token: string | null
}

const schemaRecoverPassword = yup
  .object({
    email: yup.string().email().required(),
  })
  .required()

const schemaChangePassword = yup
  .object({
    newPassword:   yup.string()
      .required()
      .min(6)
      .matches(passwordRegex.hasUppercase, 'Pelo menos 1 carácter maiúsculo')
      .matches(passwordRegex.hasLowercase, 'Pelo menos 1 carácter minúsculo')
      .matches(passwordRegex.hasNumeric, 'Pelo menos 1 carácter numérico')
      .matches(passwordRegex.hasSpecialCharacter, 'Pelo menos 1 carácter especial')
    ,
    retypeNewPassword: yup.string().oneOf([yup.ref('newPassword')], 'O campo confirmação de senha deve ter o mesmo valor do campo senha.').required(),
  })
  .required()

export const RecoverPasswordForm: React.FC<RecoverPasswordFormProps> = ({ token }) => {
  const theme = useTheme()
  const formMethods = useForm<RecoverPasswordFormData>({
    resolver: yupResolver(token ? schemaChangePassword : schemaRecoverPassword)
  })
  const navigate = useNavigate()

  const [userRecoverPassword, { loading: userRecoverPasswordIsLoading }] = useAffiliatePartnerUserRecoverPasswordMutation({
    onCompleted: () => {
      toast.success('Um link com a recuperação de senha foi enviado ao seu email!')
    },
    onError: () => {
      toast.error('Não encontramos seu E-mail :(')
    }
  })

  const [userChangePassword, { loading: userChangePasswordIsLoading }] = useAffiliatePartnerUserChangePasswordMutation({
    onCompleted: () => {
      toast.success('Sua senha foi alterada!')
      navigate('/')
    },
    onError: () => {
      toast.error('Não foi possível alterar sua senha :(')
    }
  })

  const { newPassword } = useWatch({ control: formMethods.control })

  const validatePassword = {
    hasSixDigits: Boolean(newPassword && newPassword?.length >= 6),
    hasUppercase: Boolean(newPassword?.match(passwordRegex?.hasUppercase)),
    hasLowercase: Boolean(newPassword?.match(passwordRegex?.hasLowercase)),
    hasNumeric: Boolean(newPassword?.match(passwordRegex?.hasNumeric)),
    hasSpecialCharacter: Boolean(newPassword?.match(passwordRegex?.hasSpecialCharacter)),
  }

  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))

  const handleSubmit = useCallback((data: RecoverPasswordFormData) => {
    if(token) {
      userChangePassword({
        variables: {
          params: {
            newPassword: data.newPassword,
            retypeNewPassword: data.retypeNewPassword,
            token: token || ''

          }
        }
      })
    } else {
      userRecoverPassword({
        variables: {
          params: {
            email: data.email
          }
        }
      })
    }

  }, [token])

  return (
    <FormProvider {...formMethods}>
      <form style={{ width: '100%', display: 'flex', flexDirection: 'column' }} onSubmit={formMethods.handleSubmit((data) => handleSubmit(data))}>

        {token ? (
          <Box sx={{ display: 'flex', flexFlow: 'column' }}>
            <Input
              icons={{
                start: !isLowerSm ? { element: <IconLock style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined
              }}
              type='password'
              label='Nova Senha'
              name='newPassword'
              style={{ margin: '1.2rem 0px 8px 0px' }}
            />

              <RequirementsRecover>
                <li><IconCircleFilled size={12} color={validatePassword.hasLowercase ? '#9E78BC' : theme.palette.grey[300]} /><span>Pelo menos 1 caractere minúsculo</span></li>
                <li><IconCircleFilled size={12} color={validatePassword.hasUppercase ? '#9E78BC' : theme.palette.grey[300]} /><span>Pelo menos 1 caractere maiúsculo</span></li>
                <li><IconCircleFilled size={12} color={validatePassword.hasNumeric ? '#9E78BC' : theme.palette.grey[300]} /><span>Pelo menos 1 número</span></li>
                <li><IconCircleFilled size={12} color={validatePassword.hasSpecialCharacter ? '#9E78BC' : theme.palette.grey[300]} /><span>Pelo menos 1 caractere especial</span></li>
                <li><IconCircleFilled size={12} color={validatePassword.hasSixDigits ? '#9E78BC' : theme.palette.grey[300]} /><span>Mínimo de 6 caracteres</span></li>
              </RequirementsRecover>

            <Input
              icons={{
                start: !isLowerSm ? { element: <IconLock style={{ color: '#999999' }} />, background: '#FAFAFA' } : undefined
              }}
              type='password'
              label='Confirmação de nova senha'
              name='retypeNewPassword'
              onPaste={(e) => e.preventDefault()}
              style={{ margin: '24px 0 0 0' }}
            />
          </Box>

        ) : (
          
          <Input 
            label='Email' 
            name='email' 
            icons={{ start: { element: <EmailOutlined style={{ color: '#999999' }} />, background: '#FAFAFA' } }} 
            style={{ margin: '1rem 0 0 0' }}
          />

        )}

        <Box sx={{ display: 'flex',justifyContent: 'center',padding: '1rem 0 2rem 0' }}>
          <LinkWithNoStyle to='/'>
            <Typography variant='body2'>Voltar ao login</Typography>
          </LinkWithNoStyle>
        </Box>

        <Button
          disabled={userRecoverPasswordIsLoading || userChangePasswordIsLoading}
          style={{ fontSize: '16px', fontWeight: 600 }}
          variant='contained'
          color='primary'
          type='submit'
          endIcon={userRecoverPasswordIsLoading || userChangePasswordIsLoading && <CircularProgress size={24} />}
        >
          {token ? 'Concluir redefinição' : 'Receber link para redefinir senha'}
        </Button>
      </form>
    </FormProvider>
  )
}
