import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material'
import { IconCheck, IconSearch, IconX } from '@tabler/icons-react'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckboxMultiple } from '~/components/Form/CheckboxMultiple'
import { Input } from '~/components/Form/Input'
import { SearchQueysData, useQueryString } from '~/hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { indicationOriginStatusOptions, indicationsStatusOptions } from '~/utils/options'
import * as yup from 'yup'

type FilterData = {
  name?: string,
  status?: string[],
  origin?: string[],
  begin?: string,
  end?: string
}

type FilterIndicationListDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

const schema = yup
  .object({
    begin: yup.string().test('isValid', (value, validation) => {

      if(value && Boolean(!validation.parent.end)) {
        return validation.createError({
          message: 'Este campo é obrigatório.',
          path: 'end'
        })
      }
      return true
    }),
    end: yup.string()
      .test('isValid', (value, validation) => {
        if(value && Boolean(!validation.parent.begin)) {
          return validation.createError({
            message: 'Este campo é obrigatório.',
            path: 'begin'
          })
        }
        return true
      }),
  })
  .required()

export const FilterIndicationDrawer: React.FC<FilterIndicationListDrawerProps> = ({ drawerIsOpen, toggleDrawer, setDrawerIsOpen, queryKeys }) => {
  const formMethods = useForm({
    resolver: yupResolver(schema)
  })

  const { searchQuerys, setSearchParams } = useQueryString()
  const queryValues = searchQuerys(queryKeys)

  useEffect(() => {
    if(drawerIsOpen) {
      formMethods.setValue('name', queryValues.name || '')
      formMethods.setValue('begin', queryValues.begin)
      formMethods.setValue('end', queryValues.end)
      formMethods.setValue('status', queryValues.status)
      formMethods.setValue('origin', queryValues.origin)
    }
  }, [drawerIsOpen])

  const handleFilters = (filters: FilterData) => {
    console.log(filters)
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      if(filters?.status && filters?.status?.length > 0 || queryValues?.status?.length > 0) {
        state.set('status', JSON.stringify(filters.status) || queryValues.status)
      } else {
        state.delete('status')
      }
      if(filters?.origin && filters?.origin?.length > 0 || queryValues?.origin?.length > 0) {
        state.set('origin', JSON.stringify(filters.origin) || queryValues.origin)
      } else {
        state.delete('origin')
      }
      filters.name  || queryValues.name  ? state.set('name', filters.name || queryValues.name) : state.delete('name')
      filters.begin  || queryValues.begin  ? state.set('begin', filters.begin || queryValues.begin) : state.delete('begin')
      filters.end  || queryValues.end  ? state.set('end', filters.end || queryValues.end) : state.delete('end')
      return state
    })
  }


  const onRemoveFilters = () => {
    setDrawerIsOpen(false)
    setSearchParams(state => {
      state.set('page', '0')
      state.delete('name')
      state.delete('origin')
      state.delete('status')
      state.delete('begin')
      state.delete('end')
      return state
    })
  }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={() => toggleDrawer()}>
          <IconX  />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters({ ...formData }))}>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, marginBottom: 4 }}>
            <Typography variant='h5' fontWeight={500}>Cliente ou Vendedor</Typography>
            <Input placeholder='Nome do cliente ou vendedor' fullWidth name='name' icons={{ end: { element: <IconSearch size={24} /> } }} />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, marginBottom: 4 }}>
            <Typography variant='h5' fontWeight={500}>Período de indicação</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              <Input placeholder='Data inicial' fullWidth name='begin' type='date' />
              <Typography>Até</Typography>
              <Input fullWidth name='end' type='date' />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant='h5' fontWeight={500}>Status</Typography>
            <CheckboxMultiple name='status' options={indicationsStatusOptions} />
          </Box>

           <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 4 }}>
            <Typography variant='h5' fontWeight={500}>Origem de indicação</Typography>
            <CheckboxMultiple name='origin' options={indicationOriginStatusOptions} />
          </Box>

          <Divider sx={{margin: '2rem 0'}} />

          <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Button onClick={onRemoveFilters} type='button' color='secondary'>Limpar</Button>
            <Button type='submit' color='primary' startIcon={<IconCheck size={24} />}>Aplicar</Button>
          </Box>

        </form>
      </FormProvider>
    </Drawer>
  )
}
