import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconCopy, IconLink, IconShare } from '@tabler/icons-react'
import { Bell } from '~/assets/illustrations'


export const DealershipWarning: React.FC = () => {
  const theme = useTheme()
  const isLowerSm = useMediaQuery(theme.breakpoints.down('sm'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{
      gap: 3,
      padding: isLowerSm ? 2 : 4,
      display: 'flex',
      background: '#FFFBE5',
      borderRadius: '12px',
      position: 'relative',
      flexDirection: 'column',
      border: '1px solid #F9DC45',
      flexGrow: 1
    }}>
      {!isLowerSm && <Bell style={{ position: 'absolute', top: '32px', right: '32px', fontSize: 150 }} />}

      <Typography variant='h3' color={theme.palette.grey[700]} fontWeight={600}>
        Olá, parceiro!
      </Typography>

      <Typography variant={isLowerSm ? 'body2' : 'h5'} sx={{ marginTop: 1, marginBottom: 2, lineHeight: isLowerSm ? '150%' : '30px', maxWidth: '520px' }}>
        Gostaríamos de informar que estamos enfrentando dificuldades no reconhecimento dos rateios de energia para clientes e na conexão de usinas junto à 
        <span style={{fontWeight: 500}}> concessionária Celesc</span>.
      </Typography>
      <Typography>Estamos à disposição para esclarecer dúvidas e informaremos qualquer novidade!</Typography>
      <Box sx={{display: 'flex', flexFlow: 'column', gap: '.2rem'}}>
        <Typography>Atenciosamente,</Typography>
        <Typography>Equipe Desperta Energia</Typography>
      </Box>
    </Box>
  )
}
