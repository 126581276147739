import { useTheme } from '@emotion/react'
import { Box, Button, InputAdornment, TextField, useMediaQuery, IconButton } from '@mui/material'
import { IconArrowRight, IconCopy, IconLink, IconPlus, IconSearch } from '@tabler/icons-react'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Column, useFilters, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { Card, ContentTitle, FilterButton, TableApiV2, TableHeaderApi } from '~/components'
import { UserStatus } from '~/components/UserStatus'
import { usePartner } from '~/contexts'
import { SearchQueysData, useDebounce, useQueryString, useToggle } from '~/hooks'
import { displayPhone } from '~/utils'
import { useAffiliatePartnerUserListQuery } from '~/graphql/types'
import { copyToClipboard } from '~/utils/clipboard'
import { FilterUsersDrawer } from './components'
import { toast } from 'react-toastify'

export const UsersList: React.FC = () => {
  const { myPartner, indicationLink } = usePartner()
  const theme = useTheme()
  const isLowerlg = useMediaQuery(theme.breakpoints.down('xl'))
  const isLowerMd = useMediaQuery(theme.breakpoints.down('md'))

    const queryKeys: SearchQueysData[] = [
      { key: 'name', defaultValue: '' },
      { key: 'status', defaultValue: [], parseValue: true },
    ]
    
    const { page, size, setSearchParams, searchQuerys, paginationQuerySize, searchParams } = useQueryString()
    
    const queryValues = searchQuerys(queryKeys)
    
    const [filterName, setFilterName] = useState(queryValues.name)
  
    const debouncedFilterName = useDebounce(filterName, 1000)
  
    const { isTrue: drawerIsVisible, toggle: toggleDrawer, setIsTrue: setDrawerIsVisible } = useToggle(false)

     const { data, loading: usersIsLoading } = useAffiliatePartnerUserListQuery({
        variables: {
          params: {
            pageSize: size,
            pageNumber: page,
            filter: {
              ...queryValues?.name?.length > 0 ? { nameOrUsernameOrEmail: queryValues.name } : {},
              ...queryValues?.status?.length > 0 ? { inStatus: queryValues.status } : {},
            },
            sort: {
              field: 'createdAt',
              order: -1
            }
          }
        },
        onError(error) {
          toast.error(error.message)
        },
        onCompleted(result) {
          setSearchParams(state => {
            state.set('items', String(result.affiliatePartnerUserList.meta.documentCounts))
            return state
          })
        },
        fetchPolicy: 'no-cache'
      })
  
    useEffect(() => {
      setSearchParams(state => {
        if (debouncedFilterName) {
          state.set('name', debouncedFilterName)
          state.set('page', '0')
        } else {
          state.delete('name')
        }
        return state
      })
    }, [debouncedFilterName])
  

  const columns = useMemo((): Column[] => {
    return [
      {
        Header: 'Nome',
        accessor: 'name',
      },
      {
        Header: 'E-mail',
        accessor: 'email',
        Cell: ({ value }) => {
          return <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</div>
        }
      },
      {
        Header: 'Telefone',
        accessor: 'phone',
        Cell: ({ value }) => value && <>{displayPhone(value)}</>
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return (
            <Box sx={{ padding: '.8rem 0' }}>
              <UserStatus status={value} />
            </Box>
          )
        }
      },
      {
        Header: 'Acessar',
        accessor: '_id',
        Cell: ({ value }) => {
          return (
            <Link to={`/app/users/update/${value}`}>
              <IconButton><IconArrowRight /></IconButton>
            </Link>
          )
        }
      },
    ]
  }, [])

  const dataWithMemo = useMemo(() => data?.affiliatePartnerUserList.data || [], [data])

  const defaultColumnHiddens = isLowerlg ? [].concat(isLowerMd ? [] : []) : []

  const tableMethods = useTable({
    columns,
    data: dataWithMemo,
    manualPagination: true,
    initialState: {
      hiddenColumns: defaultColumnHiddens
    },
    defaultColumn: {
      Filter: <></>
    },
  }, 
  useFilters,
  useSortBy,
  usePagination,
  useRowSelect,
  //disable checkboxColumn
  // (hooks) => renderTableCheckbox(hooks)
  )

  return (
    <Box sx={{ minHeight: '100vh', height: '100%' }}>
      <Card>

        <ContentTitle
          title='Lista de usuários'
          style={{ padding: '0px 0px 1.6rem 0px' }}
          description='Consulte a lista de usuários cadastrados'
          rightContent={
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              {myPartner?.configuration.allowAffiliateLink && (
                <Button sx={{ 
                  color: theme.palette.grey[400],
                  border: `1px solid ${theme.palette.grey[400]}` 
                }}
                color='secondary' 
                startIcon={<IconLink />} 
                endIcon={<IconCopy color={theme.palette.primary.main} />}
                onClick={() => copyToClipboard(indicationLink)}
                >
                Link de indicação
                </Button>
              )}
             
              <Link to='/app/users/create'>
                <Button endIcon={<IconPlus />} >Novo usuário</Button>
              </Link>
            </Box>
          }
          breadcrumbLinks={{ currentLink: 'Lista de usuários', links: [{ href: '/app/users', label: 'usuários' }] }}
        />


      
     
        <TableHeaderApi
          startElement={(
            <>
            <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
              <TextField
                fullWidth
                sx={{ maxWidth: '400px' }}
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                placeholder='Procurar um usuário por nome ou E-mail'
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position='end'>
                        <IconSearch />
                      </InputAdornment>
                    </>
                  )
                }}
              />
              <FilterButton onCustomClear={() => setFilterName('')} filtersToClear={queryKeys} toggleDrawer={toggleDrawer} filterCounter={searchParams.size - paginationQuerySize} />
              <FilterUsersDrawer queryKeys={queryKeys} drawerIsOpen={drawerIsVisible} setDrawerIsOpen={setDrawerIsVisible} toggleDrawer={toggleDrawer} />
             </Box>
            </>
            
          )}
        />

        <TableApiV2
          tableLayout={isLowerMd ? 'auto' : 'fixed'}
          isLoading={usersIsLoading}
          tableMethods={tableMethods}
          columns={columns}
        />

      </Card>
    </Box>
  )
}
