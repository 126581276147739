import { Box, Button, Divider, Drawer, IconButton, Typography } from "@mui/material"
import { IconCheck, IconX } from "@tabler/icons-react"
import { FormProvider, useForm } from "react-hook-form"
import { CheckboxMultiple, Input } from "~/components"
import { SearchQueysData, useQueryString } from "~/hooks"
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup"
import { useEffect } from "react"
import { comissionStatusOptions } from "~/utils/options"
import { ECommissionStatus } from "~/graphql/types"

type FilterData = {
  name?: string,
  status?: string[],
  begin?: string,
  end?: string
}

type FilterComissionsListDrawerProps = {
  drawerIsOpen: boolean
  setDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  toggleDrawer: () => void
  queryKeys: SearchQueysData[]
}

const schema = yup
  .object({
    begin: yup.string().test('isValid', (value, validation) => {

      if(value && Boolean(!validation.parent.end)) {
        return validation.createError({
          message: 'Este campo é obrigatório.',
          path: 'end'
        })
      }
      return true
    }),
    end: yup.string()
      .test('isValid', (value, validation) => {
        if(value && Boolean(!validation.parent.begin)) {
          return validation.createError({
            message: 'Este campo é obrigatório.',
            path: 'begin'
          })
        }
        return true
      }),
  })
  .required()

export const FilterComissionsDrawer: React.FC<FilterComissionsListDrawerProps> = ({ drawerIsOpen, queryKeys, setDrawerIsOpen, toggleDrawer }) => {

    const formMethods = useForm({
      resolver: yupResolver(schema)
    })
  
    const { searchQuerys, setSearchParams } = useQueryString()
    const queryValues = searchQuerys(queryKeys)

    useEffect(() => {
      if(drawerIsOpen) {
        formMethods.setValue('name', queryValues.name || '')
        formMethods.setValue('begin', queryValues.begin)
        formMethods.setValue('end', queryValues.end)
        formMethods.setValue('status', queryValues.status)
      }
    }, [drawerIsOpen])
  
    const handleFilters = (filters: FilterData) => {
      setDrawerIsOpen(false)
      setSearchParams(state => {
        state.set('page', '0')
        if(filters?.status && filters?.status?.length > 0 || queryValues?.status?.length > 0) {
          state.set('status', JSON.stringify(filters.status) || queryValues.status)
        } else {
          state.delete('status')
        }
        filters.name  || queryValues.name  ? state.set('name', filters.name || queryValues.name) : state.delete('name')
        filters.begin  || queryValues.begin  ? state.set('begin', filters.begin || queryValues.begin) : state.delete('begin')
        filters.end  || queryValues.end  ? state.set('end', filters.end || queryValues.end) : state.delete('end')
        return state
      })
    }
  
  
    const onRemoveFilters = () => {
      setDrawerIsOpen(false)
      setSearchParams(state => {
        state.set('page', '0')
        state.delete('name')
        state.delete('status')
        state.delete('begin')
        state.delete('end')
        return state
      })
    }

  return (
    <Drawer PaperProps={{ sx: { maxWidth: '560px', width: '100%', padding: '2rem' } }} anchor='right' open={drawerIsOpen} onClose={toggleDrawer}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography fontWeight={500} variant='h5'>Filtrar por</Typography>
        <IconButton type='button' onClick={toggleDrawer}>
          <IconX />
        </IconButton>
      </Box>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit((formData) => handleFilters({ ...formData }))}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>

            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4, paddingTop: '2rem' }}>
              <Typography variant='h5' fontWeight={500}>Período de indicação</Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                <Input placeholder='Data inicial' fullWidth name='begin' type='date' />
                <Typography>Até</Typography>
                <Input fullWidth name='end' type='date' />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant='h5' fontWeight={500}>Status</Typography>
              <CheckboxMultiple name='status' options={comissionStatusOptions.filter(item => item.value === ECommissionStatus.notPaid || item.value === ECommissionStatus.paid)} />
            </Box>

            <Divider />

            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
              <Button onClick={onRemoveFilters} type='button' color='secondary'>Limpar</Button>
              <Button type='submit' color='primary' startIcon={<IconCheck size={24} />}>Aplicar</Button>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </Drawer>
  )
}